"use strict";

$(document).ready(function ($) {
  console.log($);
  var paginationContainer = document.getElementById('pagination-links');
  function applyFilters() {
    var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    var selectedCategories = [];
    $('input[name="category[]"]:checked').each(function () {
      selectedCategories.push($(this).val());
    });
    var sortOption = $('input[name="sort"]:checked').val();
    var data = {
      category: selectedCategories,
      sort: sortOption,
      paged: page
    };
    $.ajax({
      url: ajaxUrl + '?action=filter_posts&',
      type: 'GET',
      data: data,
      success: function success(response) {
        $('#posts-container').html(response);

        // Плавный скролл к началу списка постов после применения фильтров
        var postsContainer = document.getElementById('posts-container');
        var scrollToOptions = {
          top: postsContainer.getBoundingClientRect().top + window.pageYOffset - 200,
          behavior: 'smooth'
        };
        window.scrollTo(scrollToOptions);
      },
      error: function error(xhr, status, _error) {
        console.log('Error: ' + _error);
      }
    });
  }

  // Применяем фильтры при нажатии на кнопку "Применить фильтры"
  $('#apply-filters').on('click', function (e) {
    e.preventDefault();
    applyFilters(); // По умолчанию на 1-й странице
  });

  // Сбрасываем фильтры при нажатии на кнопку "Сбросить фильтры"
  $('#reset-filters').on('click', function (e) {
    e.preventDefault(); // Предотвращаем стандартное действие кнопки
    $('#filter-form')[0].reset(); // Сбрасываем форму
    applyFilters(); // Применяем фильтры после сброса, по умолчанию на 1-й странице
  });

  // Обрабатываем клики на элементы пагинации
  paginationContainer.addEventListener('click', function (e) {
    if (e.target.classList.contains('page-numbers')) {
      e.preventDefault();
      var page = 1; // По умолчанию считаем, что это первая страница
      var href = e.target.getAttribute('href');
      if (href.includes('/page/')) {
        page = href.split('/page/').pop().replace('/', ''); // Получаем номер страницы из URL
      }
      applyFilters(page); // Применяем фильтры для указанной страницы
    }
  });
});